import React, { useEffect } from "react";
import * as THREE from "three";
import TwoLayered3DObject from "../components/animations/TwoLayered3dObject";

const Projects: React.FC = () => {
  useEffect(() => {
    let scene: THREE.Scene, camera: THREE.PerspectiveCamera, renderer: THREE.WebGLRenderer;
    let particles: THREE.BufferGeometry, particleSystem: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;

    const initParticles = () => {
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x1c1c1c);

      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);
      camera.position.z = 400;

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      document.getElementById("projects-section")?.appendChild(renderer.domElement);

      particles = new THREE.BufferGeometry();
      const particleCount = 800;
      const particlePositions = new Float32Array(particleCount * 3);
      const particleSpeeds = new Float32Array(particleCount * 3);
      for (let i = 0; i < particleCount; i++) {
        particlePositions[i * 3] = Math.random() * 1600 - 800;
        particlePositions[i * 3 + 1] = Math.random() * 1600 - 800;
        particlePositions[i * 3 + 2] = Math.random() * 1600 - 800;
        particleSpeeds[i * 3] = Math.random() * 0.05 - 0.025;
        particleSpeeds[i * 3 + 1] = Math.random() * 0.05 - 0.025;
        particleSpeeds[i * 3 + 2] = Math.random() * 0.05 - 0.025;
      }
      particles.setAttribute("position", new THREE.BufferAttribute(particlePositions, 3));
      particles.setAttribute("speed", new THREE.BufferAttribute(particleSpeeds, 3));

      const particleMaterial = new THREE.PointsMaterial({
        color: 0x0099ff,
        size: 3,
        sizeAttenuation: true,
        map: new THREE.TextureLoader().load("https://threejs.org/examples/textures/sprites/disc.png"),
        alphaTest: 0.5,
        transparent: true,
      });
      particleSystem = new THREE.Points(particles, particleMaterial);
      scene.add(particleSystem);

      window.addEventListener("resize", onWindowResizeParticles, false);

      animateParticles();
    };

    const onWindowResizeParticles = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const animateParticles = () => {
      requestAnimationFrame(animateParticles);

      const particlePositions = particles.attributes.position.array as Float32Array;
      const particleSpeeds = particles.attributes.speed.array as Float32Array;
      for (let i = 0; i < particlePositions.length; i += 3) {
        particlePositions[i] += particleSpeeds[i];
        particlePositions[i + 1] += particleSpeeds[i + 1];
        particlePositions[i + 2] += particleSpeeds[i + 2];

        if (particlePositions[i] > 800 || particlePositions[i] < -800) particleSpeeds[i] *= -1;
        if (particlePositions[i + 1] > 800 || particlePositions[i + 1] < -800) particleSpeeds[i + 1] *= -1;
        if (particlePositions[i + 2] > 800 || particlePositions[i + 2] < -800) particleSpeeds[i + 2] *= -1;
      }
      particles.attributes.position.needsUpdate = true;
      renderer.render(scene, camera);
    };

    initParticles();

    return () => {
      window.removeEventListener("resize", onWindowResizeParticles);
      document.getElementById("projects-section")?.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div
      id="projects"
      className="section projects-section flex flex-col items-center p-10 bg-[#1c1c1c] relative"
    >
      <div id="projects-section" className="absolute inset-0"></div>
      <div className="projects-content relative flex flex-col items-center z-10 mt-16">
        <div className="absolute top-[-10%] left-0 right-64 mx-auto w-1/2 h-1/2">
          <TwoLayered3DObject />
        </div>
        <h2 className="text-6xl mb-4 text-white">Projects</h2>
        <span className="block w-16 h-2 bg-blue-500 mt-2 mx-auto"></span>
        <div className="flex flex-wrap justify-center w-full mt-12">
          <a href="https://diablo.trade" className="w-full md:w-1/2 lg:w-1/3 p-4 transform transition-transform duration-300 hover:scale-105">
            <div className="border border-gray-400 p-8 rounded-lg bg-transparent cursor-pointer">
              <img 
                src={require('../assets/projects/diablo4.png')} 
                alt="Diablo Trade" 
                className="w-full max-w-xs h-auto object-cover rounded-md mx-auto" 
                style={{ maxWidth: '500px', maxHeight: '500px' }}
              />
              <h3 className="text-2xl text-white mt-4 text-center">Diablo Trade</h3>
              <p className="text-white mt-2 text-center">A platform for trading items in the game Diablo.</p>
              <p className="text-gray-400 mt-2 text-center">Skills Used: React, Node.js, MongoDB</p>
            </div>
          </a>
          <a href="https://voyageressential.com" className="w-full md:w-1/2 lg:w-1/3 p-4 transform transition-transform duration-300 hover:scale-105">
            <div className="border border-gray-400 p-8 rounded-lg bg-transparent cursor-pointer">
              <img 
                src={require('../assets/projects/voyager.png')} 
                alt="Voyager Essentials" 
                className="w-full max-w-xs h-auto object-cover rounded-md mx-auto" 
                style={{ maxWidth: '500px', maxHeight: '500px' }}
              />
              <h3 className="text-2xl text-white mt-4 text-center">Voyager Essentials</h3>
              <p className="text-white mt-2 text-center">A travel essentials app for planning trips.</p>
              <p className="text-gray-400 mt-2 text-center">Skills Used: React Native, Firebase</p>
            </div>
          </a>
          <a href="/DiabloAwakened" className="w-full md:w-1/2 lg:w-1/3 p-4 transform transition-transform duration-300 hover:scale-105">
            <div className="border border-gray-400 p-8 rounded-lg bg-transparent cursor-pointer">
              <img 
                src={require('../assets/projects/diablo4awakend.png')} 
                alt="Diablo Awakened" 
                className="w-full max-w-xs h-auto object-cover rounded-md mx-auto" 
                style={{ maxWidth: '500px', maxHeight: '500px' }}
              />
              <h3 className="text-2xl text-white mt-4 text-center">Diablo Awakened</h3>
              <p className="text-white mt-2 text-center">Work in progress, coming soon for Diablo 4.</p>
              <p className="text-gray-400 mt-2 text-center">Skills Used: C#, C++, Tesseract, Assembly</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projects;
